import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import { AuthorizationRequest, getIsAuthorized } from "./api";
import { CustomError } from "./api/CustomError";
import NoAccess from "./pages/noAccess/NoAccess";
initializeIcons();

export default function App() {
    const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
    const [freeTrialEnded,setFreeTrialEnded] = useState<boolean| null>(null);
    const [isAllowed, setIsAllowed] = useState<boolean | null>(null);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const clearChatRef = useRef<(() => void) | undefined>(undefined);
    
    useEffect(() => {
        const fetchAuthorizationRequest = async () => {
            try {
                const request: AuthorizationRequest = {
                    tenantId: String(sessionStorage.getItem('tenant_id')),
                    accessToken: String(sessionStorage.getItem('access_token'))
                };
                const result = (await getIsAuthorized(request));

                setIsAuthorized(result.isAuthorized);
                setIsAllowed(true);
                setFreeTrialEnded(false);
            } catch (e) {
                if (e instanceof(CustomError)) {
                    if (e.statusCode === 401) {
                        setIsAuthorized(false);
                    }
                    if (e.statusCode === 403) {
                        setIsAllowed(false);
                    }
                    if (e.statusCode === 405) {
                        setFreeTrialEnded(true)
                    }        
                }
            }
        };

        fetchAuthorizationRequest()
            .catch(() => {
            });
    }, []);

    if (isAuthorized == null && isAllowed == null && freeTrialEnded == null) {
        return (<div></div>);
    } else if (isAuthorized === false) {
        return (<NoAccess title={"User not logged in"} subTitle={"We could not authenticate you."} freeTrialEnded={false}
            description={<p>Please, login to your Nmbrs account to access the Nmbrs Knowledge Assistant.</p>} />);
    } else if (isAllowed === false) {
        return (<NoAccess title={"access denied"} subTitle={"Sorry, you don’t have permissions to use this tool."} freeTrialEnded={false}
            description={ <p>Nmbrs Knowledge Assistant is only available for subscribers.</p>} />);

    } else if (freeTrialEnded) {
        return (
            <NoAccess
                title="De gratis vragen voor jouw omgeving zijn op!"
                subTitle="Je hebt 2 opties:"
                freeTrialEnded={freeTrialEnded}
                description={
                    <>
                        <p>1. Wachten tot volgende maand voor nieuwe gratis credits</p>
                        <p>2. De Knowledge Assistant aanschaffen voor onbeperkte toegang</p>
                        <p>De Knowledge Assistant schaf je aan in de Marketplace in jouw Nmbrs omgeving.</p>
                    </>
                }
            />
        );
        
            
    } else {
        return (

            <HashRouter>
                <Routes>
                <Route path="/" element={<Layout isStreaming={isStreaming} isLoading={isLoading} clearChatRef={clearChatRef} />}>
                        <Route index element={<Chat isStreaming={isStreaming} setIsStreaming={setIsStreaming} isLoading={isLoading} setIsLoading={setIsLoading} clearChatRef={clearChatRef} />} />
                    </Route>
                </Routes>
            </HashRouter>
        );
    }
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
