import { parsedAnswer } from "./AnswerTypes";
import { renderToStaticMarkup } from "react-dom/server";
import styles from "./Answer.module.css";

const getBracketedContent = (text: string): Array<{ match: string; content: string; index: number }> => {
    const results = [];
    let depth = 0;
    let startIndex = -1;
    let content = "";

    for (let i = 0; i < text.length; i++) {
        const char = text[i];

        if (char === "[" && depth === 0) {
            depth = 1;
            startIndex = i;
            content = "";
        } else if (char === "[" && depth > 0) {
            depth++;
            content += char;
        } else if (char === "]" && depth > 0) {
            depth--;
            if (depth === 0) {
                results.push({
                    match: text.substring(startIndex, i + 1),
                    content: content,
                    index: startIndex
                });
            } else {
                content += char;
            }
        } else if (depth > 0) {
            content += char;
        }
    }

    return results;
};

function extractCitations(text: string): {
    fragments: string[];
    citations: string[];
} {
    const fragments: string[] = [];
    const citations: string[] = [];
    let lastIndex = 0;

    const bracketedContent = getBracketedContent(text);

    for (const { match, content, index } of bracketedContent) {
        if (index > lastIndex) {
            fragments.push(text.substring(lastIndex, index));
        }

        if (content.includes("https:") || content.includes(".pdf")) {
            let citationIndex: number;
            if (citations.indexOf(content) !== -1) {
                citationIndex = citations.indexOf(content) + 1;
            } else {
                citations.push(content);
                citationIndex = citations.length;
            }

            fragments.push(
                renderToStaticMarkup(
                    <button className={styles.supContainer} title={content}>
                        <sup>{citationIndex}</sup>
                    </button>
                )
            );
        } else {
            fragments.push(match);
        }

        lastIndex = index + match.length;
    }

    if (lastIndex < text.length) {
        fragments.push(text.substring(lastIndex));
    }

    return { fragments, citations };
}

export function parseAnswer(answer: string, isStreaming: boolean): parsedAnswer {
    let trimmedAnswer = answer.trim();

    if (isStreaming) {
        let lastIndex = trimmedAnswer.length;
        for (let i = trimmedAnswer.length - 1; i >= 0; i--) {
            if (trimmedAnswer[i] === "]") {
                break;
            } else if (trimmedAnswer[i] === "[") {
                lastIndex = i;
                break;
            }
        }
        trimmedAnswer = trimmedAnswer.substring(0, lastIndex);
    }

    const { fragments, citations } = extractCitations(trimmedAnswer);

    return {
        answerHtml: fragments.join(""),
        citations
    };
}
