import { useState } from "react";
import Styles from "./OpenFeedback.module.css";
import DOMPurify from "dompurify";
import { LanguageText } from "../../utils/LanguageText";

interface Props {
    setIsFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
    feedback: string[];
    setFeedback: React.Dispatch<React.SetStateAction<string[]>>;
    setIsFeedbackSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

const OpenFeedback = ({ setIsFeedbackOpen, feedback, setFeedback, setIsFeedbackSubmitted }: Props) => {
    const [openFeedback, setOpenFeedback] = useState<string>("");

    const onSubmitClick = () => {
        if (openFeedback?.trim()) {
            const sanitizedFeedback = DOMPurify.sanitize(openFeedback).trim();
            setFeedback([...feedback, sanitizedFeedback]);
            setIsFeedbackOpen(false);
            setIsFeedbackSubmitted(true);
        }
    };

    const onFeedbackChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = ev.target.value;
        if (!value) {
            setOpenFeedback("");
        } else if (value.length <= 1000) {
            setOpenFeedback(value);
        }
    };

    return (
        <div className={Styles.openFeedbackContainer}>
            <div className={Styles.openFeedbackHeader}>
                <span className={Styles.feedbackText}>{LanguageText.OPEN_FEEDBACK_HEADER}</span>
                <button id="closeFeedbackButton" className={Styles.closeFeedbackButton} onClick={() => setIsFeedbackOpen(false)}>
                    ✕
                </button>
            </div>
            <textarea id="openFeedbackInput" className={Styles.feedbackInput} autoFocus placeholder={LanguageText.OPEN_FEEDBACK_PLACEHOLDER} onChange={onFeedbackChange} />
            <button
                id="submitFeedbackButton"
                className={Styles.feedbackButton}
                onClick={() => {
                    onSubmitClick();
                }}
            >
                {LanguageText.SUBMIT_FEEDBACK_BUTTON}
            </button>
        </div>
    );
};

export default OpenFeedback;
