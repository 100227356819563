import { useRef, useState, useEffect } from "react";
import styles from "./Chat.module.css";
import followUpStyles from "../../components/FollowUpQuestionButtons/FollowUpQuestionButtons.module.css";
import {
    chatApi,
    getIsFreeTrial,
    FreeTrialRequest,
    getTenantInfo,
    TenantRequest,
    ResponseMessage,
    ChatAppResponse,
    ChatAppRequest,
    ConversationItem,
    getExamples
} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import animationData from "../../assets/knowledge_assistant_animation.json";
import { LanguageText } from "../../utils/LanguageText";
import readNDJSONStream from "ndjson-readablestream";
import { setTimeout } from "worker-timers";
import { Stack } from "@fluentui/react";
import FollowUpQuestionButtons from "../../components/FollowUpQuestionButtons/FollowUpQuestionButtons";
import Lottie from "lottie-light-react";
import { CustomError } from "../../api/CustomError";
import FeedbackSystem from "../../components/FeedbackSystem/FeedbackSystem";
import { Constants } from "../../utils/Constants";

interface ChatProps {
    isStreaming: boolean;
    setIsStreaming: React.Dispatch<React.SetStateAction<any>>;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<any>>;
    clearChatRef: React.MutableRefObject<(() => void) | undefined>;
}

export type ExampleModel = {
    text: string;
    value: string;
};
const Chat = ({ isStreaming, setIsStreaming, isLoading, setIsLoading, clearChatRef }: ChatProps) => {
    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const conversationId = useRef<string>("");

    const [footerHeight, setFooterHeight] = useState<number>(Constants.STANDARD_FOOTER_HEIGHT);
    const [error, setError] = useState<CustomError>();
    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);
    const [selectedAnswer, setSelectedAnswer] = useState<number>(-1);
    const [conversationItems, setConversationItems] = useState<ConversationItem[]>([]);
    const [followUpQuestions, setFollowUpQuestions] = useState<string[] | null>(null);
    const [isFeedbackOpen, setIsFeedbackOpen] = useState<boolean>(false);
    const [questions, setQuestions] = useState<string[]>([]);
    const [displayName, setDisplayName] = useState<string | null>(null);
    const [subdomain, setSubdomain] = useState<string | null>(null);
    const [isFreeTrial, setIsFreeTrial] = useState<boolean | null>(null);
    const [placeholder, setPlaceholder] = useState<string | undefined>(undefined);
    const [examples, setExamples] = useState<string[]>([]);

    const getTenantId = () => {
        return String(sessionStorage.getItem("tenant_id"));
    };

    const getAccessToken = () => {
        return String(sessionStorage.getItem("access_token"));
    };

    const getUserId = () => {
        return String(sessionStorage.getItem("user_id"));
    };

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        hideFollowUpQuestions();
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setSelectedAnswer(-1);

        try {
            const messages: ResponseMessage[] = conversationItems.flatMap(ci => [
                { content: ci.question, role: "user" },
                { content: ci.response.choices[0].message.content, role: "assistant" }
            ]);
            const request: ChatAppRequest = {
                messages: [...messages, { content: question, role: "user" }],
                session_state: conversationItems.length ? conversationItems[conversationItems.length - 1].response.choices[0].session_state : null,
                accessToken: getAccessToken(),
                tenantId: getTenantId(),
                conversationId: conversationId.current,
                subdomain: subdomain ?? "",
                userId: getUserId()
            };
            setQuestions([...questions, question]);
            const response = await chatApi(request);

            if (!response.body) {
                throw new CustomError(204, "No response body");
            }

            if (conversationId.current === "") {
                conversationId.current = response.headers.get("conversationId") ?? "";
            }
            const questionId = response.headers.get("questionId") ?? "";
            const parsedResponse: ChatAppResponse = await handleAsyncRequest(question, response.body, questionId);

            const conversationItem = {
                question: question,
                questionId: questionId,
                response: parsedResponse,
                conversationId: conversationId.current
            };
            setConversationItems([...conversationItems, conversationItem]);
        } catch (e) {
            if (e instanceof CustomError) {
                setError(e);
            } else {
                setError(new CustomError(500, String(e)));
            }
        } finally {
            setIsLoading(false);
        }
    };

    const getUpdatedConversationItems = (
        prevItems: any[], 
        questionId: string, 
        questionData: { 
            question: string, 
            response: ChatAppResponse, 
            conversationId: string 
        }
    ) => {
        const filteredItems = prevItems.filter(item => item.questionId !== questionId);
        return [...filteredItems, {
            question: questionData.question,
            questionId: questionId,
            response: questionData.response,
            conversationId: questionData.conversationId
        }];
    };
    
    const updateState = async (
        answer: string, 
        askResponse: ChatAppResponse, 
        question: string, 
        questionId: string, 
        newContent: string
    ): Promise<string> => {
        const updatedAnswer = answer + newContent;
        
        const latestResponse: ChatAppResponse = {
            ...askResponse,
            choices: [{ 
                ...askResponse.choices[0], 
                message: { 
                    content: updatedAnswer, 
                    role: askResponse.choices[0].message.role 
                } 
            }]
        };
        
        const questionData = {
            question,
            response: latestResponse,
            conversationId: conversationId.current
        };
        
        setConversationItems(prevItems => 
            getUpdatedConversationItems(prevItems, questionId, questionData)
        );
        
        await new Promise(resolve => setTimeout(resolve, 5));
        return updatedAnswer;
    };
    
    const handleAsyncRequest = async (
        question: string,
        responseBody: ReadableStream<any>,
        questionId: string
    ) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {} as ChatAppResponse;
       
        try {
            setIsStreaming(true);
            for await (const event of readNDJSONStream(responseBody)) {
                if (event.choices?.[0]?.context?.data_points) {
                    event["choices"][0]["message"] = event["choices"][0]["delta"];
                    askResponse = event as ChatAppResponse;
                } else if (event.choices?.[0]?.delta?.content) {
                    setIsLoading(false);
                    answer = await updateState(answer, askResponse, question, questionId, event["choices"][0]["delta"]["content"]);
                } else if (event["choices"]?.[0]?.["context"]) {
                    askResponse.choices[0].context = { ...askResponse.choices[0].context, ...event["choices"][0]["context"] };  
                } else if (event["error"]) {
                    throw new CustomError(event["errorCode"] ?? 500, event["error"]);
                }
            }  
        } finally {
            setIsStreaming(false);
        }
        
        const fullResponse: ChatAppResponse = {
            ...askResponse,
            choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
        };
        
        return fullResponse;
    };

    const FreeTrialRequest = async () => {
        try {
            const request: FreeTrialRequest = {
                accessToken: getAccessToken(),
                tenantId: getTenantId()
            };
            const result = await getIsFreeTrial(request);
            setIsFreeTrial(result.freeTrial);
            return result.freeTrial;
        } catch (e) {
            setIsFreeTrial(false);
            return false;
        }
    };

    const getFooterHeight = () => {
        return footerHeight + (followUpQuestions && !isFeedbackOpen ? Constants.STANDARD_FOLLOWUPQ_HEIGHT : 0)
    }

    const hideFollowUpQuestions = () => {
        const buttons = document.querySelectorAll(`.${followUpStyles.followUpQuestion}`);
        buttons.forEach(button => button.classList.add(followUpStyles.hideQuestions));
    };

    const unhideFollowUpQuestions = () => {
        const buttons = document.querySelectorAll(`.${followUpStyles.followUpQuestion}`);
        buttons.forEach(button => button.classList.remove(followUpStyles.hideQuestions));
    };

    const loadExamples = async () => {
        const examplesData = await getExamples();
        if (examplesData.length > 0) {
            setExamples(examplesData.slice(0, examplesData.length - 1));
            setPlaceholder(examplesData[examplesData.length - 1]);
        }
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example).catch(() => {});
    };

    const onShowCitation = (citation: string, index: number) => {
        setSelectedAnswer(index);
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }
    };
    
    const clearChat = () => {
        if (isLoading || isStreaming) return;
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setConversationItems([]);
        conversationId.current = "";
    };


    useEffect(() => {
        clearChatRef.current = clearChat;
    }, [clearChatRef, isLoading, isStreaming]);

    useEffect(() => {
        if (conversationItems.length == 0) {
            setFollowUpQuestions(null);
        } else {
            setFollowUpQuestions(conversationItems[conversationItems.length - 1].response.choices[0].context.followup_questions);
        }
    }, [conversationItems]);

    useEffect(() => {
        loadExamples();
        const fetchFreeTrial = async () => {
            return await FreeTrialRequest();
        };

        fetchFreeTrial().catch(() => {});
    }, []);

    useEffect(() => {
        if (isFreeTrial == null) {
            return;
        }
        const fetchTenant = async () => {
            const tenantRequest: TenantRequest = {
                tenantId: getTenantId(),
                accessToken: getAccessToken(),
                userId: getUserId()
            };
            return await getTenantInfo(tenantRequest);
        };

        fetchTenant().then(result => {     
            if (isFreeTrial) {
                setDisplayName("Nmbrs");
                document.title = `Nmbrs Knowledge Assistant`;                
            }
            else {                
                setDisplayName(result.displayName);
                document.title = `${result.displayName} Knowledge Assistant`;
            }
            setSubdomain(result.subdomain);
        });
    }, [isFreeTrial]);

    useEffect(() => {
        if (isFeedbackOpen) {
            hideFollowUpQuestions();
        } else {
            unhideFollowUpQuestions();
        }
    }, [isFeedbackOpen]);

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading, error]);
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [conversationItems, isFeedbackOpen, footerHeight]);
    useEffect(() => {
        if (selectedAnswer === conversationItems.length - 1) {
            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" });
        }
    }, [activeAnalysisPanelTab]);

    return (
        <div className={styles.container}>
            <div
                style={{
                    maxHeight: `calc(100vh - var(--header-height) - ${getFooterHeight()}px)`
                }}
                className={styles.chatRoot}
            >
                <div
                    style={{
                        maxHeight: `calc(100vh - var(--header-height) - ${getFooterHeight()}px)`
                    }}
                    className={styles.chatContainer}
                >
                    {!lastQuestionRef.current ? (
                        <div
                            style={{
                                maxHeight: `calc(100vh - var(--header-height) - ${getFooterHeight()}px)`
                            }}
                            className={styles.chatEmptyState}
                        >
                            <div className={styles.chatAnimationContainer}>
                                <Lottie animationData={animationData} loop={true} style={{ height: "100%", width: "100%" }} />
                            </div>
                            {isFreeTrial ? (
                                <h1 className={styles.chatEmptyStateTitle}>Nmbrs Knowledge Assistant</h1>
                            ) : (
                                <h1 className={styles.chatEmptyStateTitle}>{displayName} Knowledge Assistant</h1>
                            )}
                            <h2 className={styles.chatEmptyStateSubtitle}>{LanguageText.EMPTY_STATE_SUBTITLE}</h2>
                            <ExampleList examples={examples} onExampleClicked={onExampleClicked} />
                        </div>
                    ) : (
                        <div
                            style={{
                                maxHeight: `calc(100vh - var(--header-height) - ${footerHeight + Constants.STANDARD_FOLLOWUPQ_HEIGHT}px)`
                            }}
                            className={`${styles.chatMessageStream} ${styles.hideScrollbar} ${
                                activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab
                                    ? styles.chatMessageStreamSourceOpened
                                    : styles.chatMessageStreamSourceClosed
                            }`}
                        >
                            {conversationItems.map((conversationItem, index) => (
                                <div key={conversationItem.questionId}>
                                    <UserChatMessage message={conversationItem.question} />
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            conversationItem={conversationItem}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            isStreaming={isStreaming}
                                            onCitationClicked={c => onShowCitation(c, index)}
                                            answerIndex={index}
                                        />
                                        {!isLoading && !isStreaming && index === conversationItems.length - 1 && !error && (
                                            <FeedbackSystem
                                                conversationId={conversationItem.conversationId}
                                                questionId={conversationItem.questionId}
                                                isFeedbackOpen={isFeedbackOpen}
                                                setIsFeedbackOpen={setIsFeedbackOpen}
                                                hide={false}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGpt}>
                                        <AnswerError customError={error} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}
                </div>

                {activeAnalysisPanelTab && <AnalysisPanel activeCitation={activeCitation} footerHeight={footerHeight}/>}

                <div style={{ height: `${getFooterHeight()}px` }} className={styles.chatFooter}>
                    {(
                        <Stack
                            aria-label="follow-up questions"
                            horizontalAlign="center"
                            className={followUpStyles.followUpQuestions}
                            horizontal
                            wrap
                            tokens={{ childrenGap: 5 }}
                        >
                            <FollowUpQuestionButtons followUpQuestions={followUpQuestions} onFollowUpQuestionClicked={q => makeApiRequest(q)} />
                        </Stack>
                    )}
                    <div className={styles.commandsContainer}>
                        <div className={styles.chatInput}>
                            <QuestionInput
                                clearOnSend
                                placeholder={placeholder}
                                sendDisabled={isLoading || isStreaming}
                                onSend={question => makeApiRequest(question)}
                                clearChatDisabled={!lastQuestionRef.current || isLoading || isStreaming}
                                clearChat={clearChat}
                                setFooterHeight={setFooterHeight}
                            />
                        </div>
                    </div>

                    <div className={styles.chatDisclaimer}>
                        <p>{LanguageText.DISCLAIMER}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
